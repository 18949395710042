/// <reference path="references.ts"/>
var registrarActivity = {
    /**
     * Initialise registrar activity custom js
     */
    init: function () {
        this.watchActivityRecord();
    },
    /**
     * Watch activity record button clicks
     */
    watchActivityRecord: function () {
        $('.cpd-summary__journal-open-registrar-form').on('click', function (e) {
            e.preventDefault();
            var $closestTableRow = $(this).closest('tr');
            if (!$closestTableRow.hasClass('recording')) {
                registrarActivity.openActivityForm($(this));
            }
        });
    },
    /**
     * Open activity form
     *
     * @param $buttonInstance clicked button instance
     */
    openActivityForm: function ($buttonInstance) {
        var target_row = $buttonInstance.closest('tr');
        var target_row_height = target_row.height();
        var record_form = target_row.closest('.cpd-summary__journal-container').find('.cpd-summary__journal-form');
        var previous_logs = $buttonInstance.data('logs');
        main.resetCpdForm();
        // Set module id on the form
        $('[name=module_id]').val($buttonInstance.attr('data-module'));
        $('[name=max_file_uploads]').val($buttonInstance.attr('data-max-file-uploads'));
        $('[name=log_count]').val($buttonInstance.attr('data-log-count'));
        var log_count = $('[name=log_count]').val();
        var max_file_uploads = $('[name=max_file_uploads]').val();
        var form_wrapper = $('.cpd-summary__journal-form-wrapper');
        var form_wrapper_message = $('.cpd-summary__journal-form-wrapper__message');
        if (max_file_uploads !== '' && (log_count >= max_file_uploads)) {
            form_wrapper.hide();
            form_wrapper_message.show();
        }
        else {
            form_wrapper.show();
            form_wrapper_message.hide();
        }
        // Populate the previous logs table if we have the data, else hide the section
        record_form.find('.cpd-summary__journal-table').hide();
        if (previous_logs != '') {
            // Show the previous log table and clear the content
            record_form.find('.cpd-summary__journal-table').show();
            var table_data = record_form.find('.cpd-summary__journal-table-data');
            table_data.html('');
            var view_type = record_form.find('.cpd-summary__journal-table').attr('data-view-type');
            // Populate the previous log data area
            for (var i = 0; i < previous_logs.length; i++) {
                var prev_log = previous_logs[i];
                var log_json = JSON.stringify(prev_log);
                log_json = log_json.replace(/(["])/g, "&quot;");
                prev_log.title = (prev_log.title == null) ? 'Admin uploaded file' : prev_log.title;
                if (view_type == 'read') {
                    var update_btn = '';
                    var del_btn = '';
                }
                else {
                    var update_btn = '<span class="cpd-summary__journal-old-update registrar-portal-button" data-log="' + log_json + '">Update</span>';
                    var del_btn = '<span class="cpd-summary__journal-old-del registrar-portal-button" data-id="' + prev_log.id + '">Delete</span>';
                }
                var data_section = '<div class="cpd-summary__journal-item">' + update_btn + del_btn + '<span class="date-logname">' + prev_log.updated_at + ' - ' + prev_log.title + '</span>';
                if (prev_log.filepath != "" && prev_log.filepath != null) {
                    data_section += '<a href="/' + prev_log.filepath.replace('public/', 'storage/') + '" target = "_blank" class="doc_icon"><span class="cpd-summary__journal-icon"></span></div>';
                }
                else {
                    data_section += '</div>';
                }
                table_data.prepend(data_section);
            }
            // Attach click event to the previous log's update button
            table_data.find('.cpd-summary__journal-old-update').click(function (e) {
                e.preventDefault();
                main.resetCpdRecordForm();
                registrarActivity.loadPrevLogForm(this, record_form);
            });
            // Attach click event to the previous log's delete button
            table_data.find('.cpd-summary__journal-old-del').click(function (e) {
                e.preventDefault();
                var row = $(this).closest('.cpd-summary__journal-item');
                var id = $(this).data('id');
                $.ajax({
                    url: '/member/registrar/record/delete/' + id
                })
                    .done(function (data) {
                    // Remove row from DOM
                    row.remove();
                    var log_count = $('[name=log_count]').val();
                    log_count = $('[name=log_count]').val(log_count - 1).val();
                    var max_file_uploads = $('[name=max_file_uploads]').val();
                    var form_wrapper = $('.cpd-summary__journal-form-wrapper');
                    var form_wrapper_message = $('.cpd-summary__journal-form-wrapper__message');
                    if (log_count >= max_file_uploads) {
                        form_wrapper.hide();
                        form_wrapper_message.show();
                    }
                    else {
                        form_wrapper.show();
                        form_wrapper_message.hide();
                    }
                });
            });
        }
        // Open the target row
        target_row.addClass('recording');
        target_row.find('td').css('padding-bottom', record_form.height() + 21);
        $(this).hide();
        // Show the form and scroll to it after a show delay
        setTimeout(function () {
            record_form.css('top', target_row.position().top + target_row_height);
            record_form.fadeIn('300ms');
            main.scrollToElement(target_row, 0);
        }, 300);
    },
    /**
     * Load form with data when user trying to edit activity
     *
     * @param button button instance
     * @param record_form form instance
     * @returns
     */
    loadPrevLogForm: function (button, record_form) {
        // Populate the form with value if a log for the activity exists
        var log = $(button).attr('data-log');
        if (!log) {
            return false;
        }
        var log_details = JSON.parse(log);
        record_form.find('.form-heading').html('Update log - ' + log_details.title);
        record_form.find('input[name="log_id"]').val(log_details.id);
        record_form.find('input[name="title"]').val(log_details.title);
        record_form.find('textarea[name="description"]').val(log_details.description);
        record_form.find('input, textarea').addClass('has-input');
        if (log_details.filepath) {
            record_form.find('.cpd-summary__journal-form-file-link').show();
            record_form.find('.cpd-summary__journal-form-file-link').attr('href', '/' + log_details.filepath);
            record_form.find('.cpd-summary__journal-form-label--file').html('Re-upload record');
        }
        main.scrollToElement(record_form, 0);
    },
};
